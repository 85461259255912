<template>
    <DynamicForm class="flex flex-wrap mr-[-8px] ml-[-8px]" ref="dynamicForm" :form="form" @submitted="(values) => submitForm(values)" @change="changedForm">
      <template #select_input="{ control, onChange, onFocus, onBlur }">
        <div v-tooltip.top="control.customStyles?.tooltip">
          <Dropdown
            @update:modelValue="
              (values) => {
                control.value =
                  values?.value || control.customStyles?.editable ? values : '';
                onBlur(values);
              }
            "
            :ref="`dropdown_${control.name}`"
            :editable="control.customStyles?.editable === true ? true : false"
            :emptyMessage="control.customStyles?.emptyMessage || 'Nenhum resultado encontrado'"
            :emptyFilterMessage="'Nenhum resultado encontrado'"
            :showClear="!!control.value"
            :disabled="control.disabled"
            v-model="control.value"
            :virtualScrollerOptions="control.customStyles?.virtualScrollerOptions && { itemSize: 38 }"
            :options="
              control.customStyles?.editable == true
                ? control.options.filter((option) =>
                    option.label
                      ?.toLowerCase()
                      ?.includes(
                        typeof control.value === 'object'
                          ? control.value?.label?.toLowerCase()
                          : control.value?.toLowerCase()
                      )
                  )
                : control.options
            "
            :loading="control.customStyles?.loading || false"
            :filter="control.customStyles?.filter === false ? false : true"
            :panelClass="(control.customStyles?.editable || control.customStyles?.canAddNewOptions) ? 'editable-droppdown' : ''"
            :panelStyle="{ width: width_popup + 'px' }"
            optionLabel="label"
            :placeholder="control.disabled ? '': control.customStyles?.placeholder || 'Selecione uma opção'"
            :class="
              control.valid === true
                ? 'w-full mr-4 p-disabled-components'
                : 'w-full mr-4  p-invalid p-disabled-components'
            "
            @before-show="updatePopupWidth(`${control.name}`)"
            @focus="onFocus"
            @blur="
              (values) => {
                if (values.target?.ariaLabel == (control.customStyles?.placeholder || 'Selecione uma opção')) {
                  control.validations.find((validation) => {
                    if (control.value == '' && validation.type === 'required') {
                      control.valid = false;                  
                    }
                  });
                    onChange(values);
                }
                if (control.customStyles?.editable && !control.value) {
                  onChange(values);
                }
              }
            "
          >
              <template
                v-if="control.customStyles?.propetyFilter?.length"
                #value="slotProps"
              >
                {{
                  slotProps?.value?.label?.split("$separator")?.[0] || control.customStyles?.placeholder || 'Selecione uma opção'
                }}
              </template>
              <template
                v-if="control.customStyles?.propetyFilter?.[0]"
                #option="slotProps"
              >
                <div class="flex flex-col">
                  <span style="white-space: normal;">{{
                    slotProps.option.value[control.customStyles?.propetyFilter[0]]
                  }}</span>
                  <span
                    v-if="control.customStyles?.propetyFilter[1]"
                    class="ml-2"
                    style="white-space: normal; font-size: 10px;"
                    >{{
                      slotProps.option.value?.[
                        control.customStyles?.propetyFilter[1]
                      ]
                    }}</span
                  >
                </div>
            </template>
            <template #option="slotProps" v-else>
              <div>
                <p style="white-space: normal">
                  {{ slotProps.option.label }}
                </p>
              </div>
            </template>
            
            <template #clearicon>
              <i
                class="p-icon p-dropdown-clear-icon pi pi-times"
                @click="control.value = ''"
                style="cursor: pointer"
              ></i>
            </template>
            <template #emptyfilter v-if="control.customStyles?.canAddNewOptions">          
              <div
                v-if="$refs[`dropdown_${control.name}`]?.filterValue"
                @click="emitCanAddNewOptions(control, $refs[`dropdown_${control.name}`])"
                style="padding: 0.5rem 1rem"
              >
                <p
                  style="white-space: normal"
                  class="p-dropdown-item-label"
                  data-pc-section="itemlabel"
                >
                  Deseja adicionar:
                  <b style="white-space: normal; max-width: 408px">{{
                    $refs[`dropdown_${control.name}`]?.filterValue
                  }}</b>
                  ?
                </p>
              </div>
            </template>
            <template #empty v-if="control.customStyles?.editable">
              <div
                v-if="control.value"
                @click="$emit('editableValue', control?.value)"
                style="padding: 0.5rem 1rem"
              >
                <p
                  style="white-space: normal"
                  class="p-dropdown-item-label"
                  data-pc-section="itemlabel"
                >
                  Deseja adicionar:
                  <b style="white-space: normal; max-width: 408px">{{
                    control.value
                  }}</b>
                  ?
                </p>
              </div>
              <div v-else style="padding: 0.5rem 1rem">
                <span class="p-dropdown-item-label" data-pc-section="itemlabel"
                  >Nenhum resultado encontrado</span
                >
              </div>
            </template>
          </Dropdown>

        </div>

      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">{{ control.validations[0]?.text }}</p>
      </div>
      </template>
      <template #input_switch="{ control, onChange, onFocus, onBlur }">
        <div class="flex">
          <InputSwitch
            v-tooltip.top="control.customStyles?.tooltip"
            v-if="control"
            v-model="control.value"
            :class="
              control.valid === true
                ? 'mr-2 p-disabled-components '
                : 'mr-2  p-invalid p-disabled-components'
            "
            :disabled="control.disabled"
            :trueValue ="true"
            :falseValue="false"
          />
          <span class="text-dark-second">{{control.value ? 'Sim' : 'Não'}}</span>
          </div>
          <div v-if="!control.valid" class="form-errors">
            <p class="form-error">
              {{ control.validations[0].text }}
            </p>
          </div>
      </template>
      <template #input_text_area="{ control, onChange, onFocus, onBlur }">
      <Textarea 
        v-if="control"
        :id="control.name"
        v-model="control.value"
        v-tooltip.top="control.customStyles?.tooltip"
        ref="textarea"
        :rows="control.customStyles?.rows || '5'" 
        autoResize
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components'
            : 'w-full mr-4  p-invalid p-disabled-components'
        "
        :placeholder="control.disabled ? '': control.customStyles?.placeholder || 'Digite um texto'"
        :style="control.customStyles?.style || 'max-height: 300px'"
        :disabled="control.disabled"
        :type="control.type"
        :name="control.name"
        @update:modelValue="
          (values) => {
            control.value = values;
            onBlur(values);
          }
        "
        @change="onChange"
        @focus="onFocus"
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">
          {{ control.validations[0].text }}
        </p>
      </div>
      </template>
      <template #input_text="{ control, onChange, onFocus, onBlur }">
      <InputText
        v-if="control"
        :id="control.name"
        v-model="control.value"
        v-tooltip.top="control.customStyles?.tooltip"
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components dd-inputtext'
            : 'w-full mr-4  p-invalid p-disabled-components dd-inputtext'
        "
        :placeholder="control.disabled ? '': control.customStyles?.placeholder || 'Digite um texto'"
        :disabled="control.disabled"
        :type="control.type"
        :name="control.name"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">
          {{ control.validations[0].text }}
        </p>
      </div>
      </template>
      <template #input_mult_mask="{ control, onChange, onFocus, onBlur }">
      <InputText
        @keydown="
          (event) => {
            const masks = control.customStyles.masks;
            if (
              masks[masks.length - 1].length === control.value.length &&
              !['Tab', 'Delete', 'Backspace'].includes(event.key)
            ) {
              event.preventDefault();
            }
            control.value = applyDynamicMask(masks, control.value);
          }
        "
        v-if="control"
        v-tooltip.top="control.customStyles?.tooltip"
        :id="control.name"
        v-model="control.value"
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components dd-inputtext'
            : 'w-full mr-4  p-invalid p-disabled-components dd-inputtext'
        "
        :placeholder="control.disabled ? '': control.customStyles?.placeholder || '' "
        :disabled="control.disabled"
        :type="control.type"
        :name="control.name"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">
          {{ control.validations[0].text }}
        </p>
      </div>
      </template>
      <template #input_mask="{ control, onChange, onFocus, onBlur }">
      <InputMask
        v-if="control"
        :id="control.name"
        v-model="control.value"
        v-tooltip.top="control.customStyles?.tooltip"
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components'
            : 'w-full mr-4  p-invalid p-disabled-components'
        "
        :mask="control.customStyles?.mask"
        :placeholder="control.disabled ? '': control.customStyles?.placeholder || '' "
        :disabled="control.disabled"
        :type="control.type"
        :name="control.name"
        @update:modelValue="
          (values) => {
            if (!values.includes('_')) {
              control.value = values;
              onBlur(values);
            }
          }
        "
        @change="onChange"
        @focus="onFocus"
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">
          {{ control.validations[0].text }}
        </p>
      </div>
      </template>
      <template #multiselect_input="{ control, onFocus, onBlur, onChange }">
      <MultiSelect
        @focus="onFocus"
        @blur="onBlur"
        @before-show="updatePopupWidth(`${control.name}`)"
        :panelStyle="{ width: width_popup + 'px' }"
        :panelClass="control.customStyles?.canAddNewOptions && 'editable-droppdown'"
        :ref="`dropdown_${control.name}`"
        :loading="control.customStyles?.loading || false"
        :filter="control.customStyles?.filter === false ? false : true"
        :disabled="control.disabled"
        v-model="control.value"
        :virtualScrollerOptions="control.customStyles?.virtualScrollerOptions && { itemSize: 38 }"
        :options="control.options"
        :emptyMessage="'Nenhum resultado encontrado'"
        :emptyFilterMessage="'Nenhum resultado encontrado'"
        :emptySelectionMessage="'Nenhum item selecionado'"
        optionLabel="label"
        :placeholder="control.disabled ? '' : control.customStyles?.placeholder || 'Selecione as opções'"
        :class="`w-full mr-4${control.valid ? '' : ' form-control--error'}`"
        v-tooltip.top="control.customStyles?.tooltip"

      >
          <template
            v-if="control.customStyles?.propetyFilter?.length"
            #value="slotProps"
          >
            {{
              slotProps?.value?.length
              ? slotProps.value.map((item) => item.label?.split("$separator")?.[0]).join(', ')
              : control.customStyles?.placeholder || 'Selecione as opções'
            }}
          </template>
          <template
            v-if="control.customStyles?.propetyFilter?.[0]"
            #option="slotProps"
          >
            <div class="flex flex-col">
              <span style="white-space: normal;">{{
                slotProps.option.value[control.customStyles?.propetyFilter[0]]
              }}</span>
              <span
                v-if="control.customStyles?.propetyFilter[1]"
                class="ml-2"
                style="font-size: 10px; white-space: normal;"
                >{{
                  slotProps.option.value?.[
                    control.customStyles?.propetyFilter[1]
                  ]
                }}</span
              >
            </div>
        </template>

        <template #option="slotProps" v-else>
          <div>
            <p style="white-space: normal;">
              {{ slotProps.option.label }}
            </p>
          </div>
        </template>
        <template #emptyfilter v-if="control.customStyles?.canAddNewOptions">          
          <div
            v-if="$refs[`dropdown_${control.name}`]?.filterValue"
            @click="emitCanAddNewOptions(control, $refs[`dropdown_${control.name}`])"
            style="padding: 0.5rem 1rem"
          >
            <p
              style="white-space: normal"
              class="p-dropdown-item-label"
              data-pc-section="itemlabel"
            >
              Deseja adicionar:
              <b style="white-space: normal; max-width: 408px">{{
                $refs[`dropdown_${control.name}`]?.filterValue
              }}</b>
              ?
            </p>
          </div>
        </template>
      </MultiSelect>
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">Este campo é obrigatório</p>
        <!--Traduzir-->
      </div>
      </template>
      <template #input_date="{ control, onChange, onFocus, onBlur }">
      <Calendar
        :disabled="control.disabled"
        v-if="control"
        :manualInput="true"
        ref="calendar_date"
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components'
            : 'w-full mr-4  p-invalid p-disabled-components'
        "
        :id="control.name"
        v-model="control.value"
        :iconDisplay="
          control?.customStyles?.iconDisplay
            ? control?.customStyles?.iconDisplay
            : 'button'
        "
        :minDate="control?.customStyles?.minDate || null"
        :placeholder="control.disabled ? '': control?.customStyles?.placeholder || 'dd/mm/aaaa'"
        showIcon
        dateFormat="dd/mm/yy"
        @update:modelValue="
          (values) => {
            control.value = validateDate(values?.toLocaleDateString('pt-br'));
            onBlur(control.value);
          }
        "
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">{{ control.validations[0]?.text }}</p>
      </div>
      </template>
      <template #input_hour="{ control, onChange, onFocus, onBlur }">
      <Calendar
        timeOnly
        showIcon
        id="calendar-timeonly"
        v-model="control.value"
        icon="pi pi-clock"
        :disabled="control.disabled"
        v-if="control"
        :manualInput="true"
        :class="
          control.valid === true
            ? 'w-full mr-4 p-disabled-components'
            : 'w-full mr-4  p-invalid p-disabled-components'
        "
        @blur="
          (values) => {
            if (values.value) control.value = validateHour(values.value);
            onBlur(values);
          }
        "
        @date-select="
          (values) => {
            control.value = values;
            onChange;
          }
        "
      />
      <div v-if="!control.valid" class="form-errors">
        <p class="form-error">{{ control.validations[0]?.text }}</p>
      </div>
      </template>
      <template #divider="{ control }">
        <div v-html="control.value"></div>
      </template>
      <template #file_upload="{ control, onChange, onFocus, onBlur }">
        <component :is="UiFileupload" v-model="control.value" :isValid="control.valid" @update:modelValue="(value)=> {
          onBlur(value);
          fileUpload[control.name] = value;
        }"/>
        <div v-if="!control.valid" class="form-errors">
          <p class="form-error">{{ control.validations[0]?.text }}</p>
        </div>
      </template>
      <template #file_table="{ control }">
        <component :is="UiTable"
          :tableItems="control.customStyles.files" 
          :tableCols="control.customStyles.cols" 
          :rowActions ="control.customStyles?.actions"  
          :paginator="false" 
        />
      </template>
    </DynamicForm>
</template>

<script>
import { ref, computed, createApp } from "vue";
import UiFileupload from "./ui-fileupload.vue";
import UiTable from "./ui-table.vue";


export default {
  name: "FormDinamic",
  emits: ["submitted", "changed", "canAddNewOptions", "editableValue"],
  setup() {
    return {
      UiFileupload,
      UiTable,
    };
  },
  data() {
    return {
      formsRef: computed(() => this.$refs.dynamicForm),
      width_popup: 0,
      fileUpload: {},
    };
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitCanAddNewOptions(control, dropdown) {
      this.$emit("canAddNewOptions", control, dropdown?.filterValue);
      dropdown.overlayVisible = false;
    },
    submitForm(values) {
      // Adiciona os arquivos ao objeto de valores
      const valueEmitted = { ...values };
      if(Object.keys(this.fileUpload).length > 0){
        Object.keys(this.fileUpload).forEach((key) => {        
          valueEmitted[key] = this.fileUpload[key];
        });
      }      
      this.$emit("submitted", valueEmitted);
    },
    changedForm(values) {
      if(values?.type == 'change') return;
      this.$emit("changed", values);
    },
    validateHour(hour) {
      // Verifica se a hora está no formato correto
      const hourRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (!hourRegex.test(hour)) {
        return "";
      }

      return hour;
    },
    updatePopupWidth(value) {
      let dropdownElement = this.$refs[`dropdown_${value}`];
      this.width_popup = dropdownElement.$el.offsetWidth;
    },
    validateDate(datePTBR) {
      // Verifica se a data está no formato correto
      const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!dateRegex.test(datePTBR)) {
        return "";
      }

      // Verifica se é uma data válida
      const [day, month, year] = datePTBR.split("/");
      const dateObject = new Date(`${year}-${month}-${day}`);
      const isValidDate = !isNaN(dateObject.getTime());

      return isValidDate ? datePTBR : "";
    },
    applyDynamicMask(masks, value) {
      const cleaned = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

      // Encontra a máscara que se encaixa com o comprimento do valor limpo
      const maskToApply =
        masks.find((mask) => {
          const numericLength = mask.replace(/\D/g, "").length;
          return cleaned.length <= numericLength;
        }) || masks[masks.length - 1]; // Usa a última máscara se nenhuma for encontrada

      return this.applyPattern(cleaned, maskToApply);
    },
    applyPattern(value, pattern) {
      let i = 0;
      const v = value.toString();
      let result = "";

      for (const char of pattern) {
        if (char === "9") {
          if (v[i]) {
            result += v[i++];
          } else {
            break;
          }
        } else {
          if (v[i]) {
            result += char;
          } else {
            break;
          }
        }
      }

      return result;
    },
  },
};
</script>
<style>
@import '../assets/css/primevue.css';

.form-label {
    font-weight: normal !important;
    margin-bottom: 0.25rem !important;
    font-size: 14px; /* 14px */
    line-height: 1.25rem;
}
.editable-droppdown .p-dropdown-empty-message, .editable-droppdown .p-multiselect-empty-message {
  margin: 0 !important;
  border: 0 none !important;
  color: #515c66 !important;
  background: transparent !important;
  padding: 0 !important;
  transition: box-shadow 0.2s !important;
  border-radius: 0 !important;
  cursor: pointer !important;
}
.editable-droppdown .p-dropdown-empty-message:hover, .editable-droppdown .p-multiselect-empty-message:hover {
  color: #515c66 !important;
  background: rgba(232, 241, 248, 0.7) !important;
}

.form-group {
  padding: 0 8px 16px 8px;
  margin-bottom: 0;
}

.form-group .form-error{
  margin-top: 0;
}
.form-required-star {
  color: red;
}
.p-inputtextarea-resizable {
  resize: none;
}
</style>