import iconHomeVue from "@/components/icon/icon-home.vue";
import iconClockVue from "@/components/icon/icon-clock.vue";
import iconMapPinVue from "@/components/icon/icon-map-pin.vue";
import iconListCheckVue from "@/components/icon/icon-list-check.vue";
import IconFlag from "@/components/ui/icon/icon-flag.vue";
import { formatDateWithoutOneDayMore } from "@/utils/date";
import { formatDate } from "@/utils/date";

export interface HistoryItemProps {
  id: number;
  value: string;
  label: string;
  icon: Function;
  metadata: Record<string, string>;
  completed: boolean;
  description?: string;
  status?: "ended" | "repproved" | "approved" | "in_progress";
}

export interface LinkedTaskProps {
  id: number;
  title: string;
  responsible: string;
  deadline: string;
  status: "repproved" | "approved" | "in_progress";
  details: string;
  metadata: {
    start_date: string;
    end_date: string;
    completed: boolean;
  };
}

export interface TableItemProps {
  Id: number;
  title: string;
  area: string;
  actual_risk: number;
  state: string;
  user_responsible: string;
  description: string;
  created_at: string;
  last_revision: string;
}

// Função para formatar dados da API
export function formatData(payload: any) {
  return payload.map((item) => {
    const residualRisk =
      item?.risk_treatment_activities?.[0]?.risk?.risk_monitoring?.[0]
        ?.residual_risk || null;

    const initialRisk =
      item?.risk_treatment_activities.map(
        (initial) => initial?.risk?.initial_risk
      ) || "";

    const actualRisk = residualRisk || initialRisk || "-";

    let riskLevel = "";
    const allRisks = `${actualRisk}`.toLowerCase();

    if (allRisks.includes("alto")) {
      riskLevel = "Alto";
    } else if (allRisks.includes("médio")) {
      riskLevel = "Médio";
    } else if (allRisks.includes("baixo")) {
      riskLevel = "Baixo";
    }
    // Formatar a data de criação no formato DD/MM/YYYY
    const formattedDate = item?.created_at
      ? (() => {
          const date = new Date(item.created_at);
          const day = String(date.getDate()).padStart(2, "0"); // Adiciona zero à esquerda se necessário
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses começam do zero, então adicionamos 1
          const year = date.getFullYear();
          return `${day}/${month}/${year}`; // Formato DD/MM/YYYY
        })()
      : null;
    const userResponsible = item?.user_responsible?.displayName || "";
    const lastRevisionDateFromHistorical = item?.log_activity_treatments
      ?.filter(
        (item) => item?.log_description?.timeline === "Inventário Revisado"
      )
      ?.sort(
        (a, b) =>
          new Date(b.date_time).getTime() - new Date(a.date_time).getTime()
      )
      ?.at(0);

    // Formatar a última data de revisão
    const lastRevisionDate = lastRevisionDateFromHistorical?.date_time
      ? lastRevisionDateFromHistorical.date_time
      : null;

    let remainingDays: string = "-";
    if (lastRevisionDate && item.to_be_reminded_at) {
      const lastRevision = new Date(lastRevisionDate);
      const toBeRemindedAt = new Date(item.to_be_reminded_at);
      const diffTime = toBeRemindedAt.getTime() - lastRevision.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) {
        remainingDays = "0 dias";
      } else if (diffDays < 0) {
        remainingDays = `${diffDays} ${diffDays === -1 ? "dia" : "dias"}`;
      } else {
        remainingDays = `${diffDays} ${diffDays === 1 ? "dia" : "dias"}`;
      }
    }

    return {
      ...item,
      Id: item?.id,
      id_temporary: item?.id_temporary,
      title: item?.name_activity,
      area: item?.area_modules?.area?.description,
      description: item?.description,
      actual_risk: riskLevel,
      state: item?.status,
      user_responsible: userResponsible,
      id_user_responsible: item?.owner?.id,
      last_revision: lastRevisionDate
        ? formatDateWithoutOneDayMore(lastRevisionDate)
        : "-",
      to_be_reminded_at: item.to_be_reminded_at
        ? formatDate(item.to_be_reminded_at)
        : "-",
      remaining_days: remainingDays || "-",
      created_at: formattedDate,
    };
  });
}
