import { defineStore } from "pinia";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { apolloClient, nhost } from "@/main";
import { gql } from "graphql-tag";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    isBlocked: null as boolean | null,
  }),

  actions: {
    async fetchUserBlockedStatus() {
      if (this.isBlocked !== null) {
        return this.isBlocked;
      }

      const { result, loading } = await provideApolloClient(apolloClient)(() =>
        useQuery(
          gql`
            query getUserBlockage($id: uuid!) {
              user(id: $id) {
                id
                user_modules(
                  where: { module: { module: { _eq: "data_mapping" } } }
                ) {
                  blocked
                  module {
                    module
                  }
                }
              }
            }
          `,
          {
            id: nhost.auth.getUser()?.id,
          },
          {
            fetchPolicy: "no-cache",
          }
        )
      );

      while (loading.value) {
        await new Promise((r) => setTimeout(r, 1000));
      }

      this.isBlocked =
        result?.value?.user?.user_modules[0]?.blocked ??
        result?.value?.user?.user_modules[0]?.blocked === undefined;

      return this.isBlocked;
    },
  },
});
