import { h, createApp, reactive } from "vue";
import singleSpaVue from "single-spa-vue";
import router from "./router";
import App from "./App.vue";
import "vue3-quill/lib/vue3-quill.css";
import "primevue/resources/themes/aura-light-green/theme.css";
import "../src/assets/css/variables.css";

// prime vue resources
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import ProgressBar from "primevue/progressbar";
import Dropdwon from "primevue/dropdown";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Badge from "primevue/badge";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tooltip from "primevue/tooltip";
import Skeleton from "primevue/skeleton";
import InputSwitch from "primevue/inputswitch";
import "@trustech/components/style.css";
import TrustechComponents from "@trustech/components";

// main app css
require("./assets/css/app.css");

// coisas do index.html
require("@/main.ts");

// URLs of the resources to load
const urls = [
  "https://fonts.googleapis.com",
  "https://fonts.gstatic.com",
  "https://kit.fontawesome.com/d5abab5315.js",
];

// Function to load a CSS file
function loadCSS(url: string) {
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  document.head.appendChild(link);
}

// Function to load a JavaScript file
function loadJS(url: string) {
  let script = document.createElement("script");
  script.src = url;
  document.body.appendChild(script);
}

// Load the resources
for (let url of urls) {
  // @ts-ignore
  if (url.endsWith(".js")) {
    loadJS(url);
  } else {
    loadCSS(url);
  }
}

// import { createGtm } from "@gtm-support/vue-gtm";
import { createDynamicForms } from "@mathmore0000/vue-dynamic-forms";
// perfect scrollbar
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
// popper
import Popper from "vue3-popper";

import { createApolloClient } from "@nhost/apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { useAppStore } from "./stores/app-store";
import { updateRole } from "./service/roles";
import { createPinia } from "pinia";
import i18n from "./i18n";
// @ts-ignore
import { nhostAuth } from "@baymetrics/trustech";

import { vMaska } from "maska";
import ProgressSpinner from "primevue/progressspinner";

const module = "data_mapping";
let apolloClient;
let nhost;
let toastG;

async function getNhostWithRetry(retries = 2, delay = 500) {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const nhostInstance = await nhostAuth();

      if (nhostInstance) {
        return nhostInstance;
      }
    } catch (error) {
      console.error(
        `Erro ao carregar nhostAuth na tentativa ${attempt}`,
        error
      );
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  console.error("nhostAuth falhou após todas as tentativas!");
  return null;
}

const vueLifecycles = (singleSpaVue as any)({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  async handleInstance(app) {
    const pinia = createPinia();
    const VueDynamicForms = createDynamicForms();

    // @ts-ignore
    nhost = await getNhostWithRetry(2, 600);

    const r = await updateRole(
      createApolloClient({ nhost }),
      (await nhost.auth.isAuthenticatedAsync()) ? nhost.auth.getUser().id : null
    );
    apolloClient = createApolloClient({
      nhost,
      headers: { "x-hasura-role": r },
    });

    app.config.globalProperties.$appState = reactive({
      isRTL: false,
      isNewThemeLoaded: false,
      layoutMode: "light",
    });

    app.use(i18n);
    app.use(pinia);
    app.use(router);
    app.use(PrimeVue);
    app.use(nhost);
    app.provide(DefaultApolloClient, apolloClient);
    //components da trustech
    app.use(TrustechComponents);
    app.component("Toast", Toast);
    app.component("Button", Button);
    app.component("FileUpload", FileUpload);
    app.component("Toast", Toast);
    app.component("ProgressBar", ProgressBar);
    app.component("Dropdown", Dropdwon);
    app.component("Dialog", Dialog);
    app.component("MultiSelect", MultiSelect);
    app.component("Textarea", Textarea);
    app.component("InputText", InputText);
    app.component("InputMask", InputMask);
    app.component("InputSwitch", InputSwitch);
    app.component("Calendar", Calendar);
    app.component("DataTable", DataTable);
    app.component("Column", Column);
    app.component("ProgressSpinner", ProgressSpinner);
    app.component("Skeleton", Skeleton);
    app.component(Badge);
    app.use(VueDynamicForms);
    app.use(ToastService);
    toastG = app.config.globalProperties.$toast;

    app.provide("appStore", useAppStore());

    // VISTRO resources
    app.use(PerfectScrollbar);
    app.component("Popper", Popper);

    //input mask
    app.directive("maska", vMaska);
    app.directive("tooltip", Tooltip);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

function updateApolloClientByRole(r) {
  apolloClient = createApolloClient({
    nhost,
    headers: { "x-hasura-role": r },
  });
}

export { updateApolloClientByRole, apolloClient, toastG, nhost, module };
