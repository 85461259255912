function parseDateBR(date: string): Date {
  const [day, month, year] = date.split("/");
  return new Date(Number(year), Number(month) - 1, Number(day)); // month is 0-indexed
}

export function formatTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function formatDate(dateTimeString) {
  // Cria um objeto Date a partir da string de entrada
  const date = new Date(dateTimeString);

  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    throw new Error("Data inválida.");
  }

  // Extrai o dia, mês e ano
  const day = String(date.getDate() + 1).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Mês começa do zero
  const year = date.getFullYear();

  // Formata no padrão DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export function formatDateWithoutOneDayMore(dateTimeString) {
  // Remove frações de segundos para normalizar a data
  const normalizedString = dateTimeString.replace(/\.\d+/, "");
  const date = new Date(normalizedString);

  // Verifica se a data é válida
  if (isNaN(date.getTime())) {
    throw new Error("Data inválida.");
  }

  // Extrai o dia, mês e ano como UTC
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  // Formata no padrão DD/MM/YYYY
  return `${day}/${month}/${year}`;
}
