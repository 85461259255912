import { formatData } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps } from "@/service/table-data";
import { getInventoryByFilter } from "@/api/ts/inventory";

export type FormState = Ref<Record<string, string>>;
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  selectedStatus: Ref<string>;
  selectedFilterStatus: Ref<string>;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    selectedStatus: ref(""),
    selectedFilterStatus: ref(""),
  }),
  actions: {
    setInitialTableItems(payload: any) {
      const dataFormatted = formatData(payload);
      this.filteredDataItems = dataFormatted;
      this.dataItems = dataFormatted;
    },

    setTableCustomFilter(payload: any) {
      this.filterState = payload;
      getInventoryByFilter(payload)
        .then((filteredData) => {
          let formattedData = formatData(filteredData);
          if (this.selectedStatus) {
            formattedData = formattedData.filter(
              (item: any) => item.status === this.selectedStatus
            );
          }

          this.filteredDataItems = formattedData;
        })
        .catch((error) => {
          console.error("Erro ao buscar dados filtrados:", error);
        });
    },
    setTableFilter(
      status?: string | null | { value: string },
      filter?: string | null,
      isFavorite: boolean = false
    ) {
      this.selectedStatus = status;
      const activeFilter = this.filterState || {};

      getInventoryByFilter({
        ...activeFilter,
        status: status ?? activeFilter.status,
        filter: filter ?? activeFilter.filter,
      })
        .then((filteredData) => {
          let formattedData = formatData(filteredData);

          const temporaryPendingData = this.dataItems.filter((item: any) => {
            return item.id_temporary && item.state === "Em preenchimento";
          });

          let mergedData = [...temporaryPendingData, ...formattedData];

          if (status) {
            const statusArray = Array.isArray(status) ? status : [status];

            if (status === "Pendente") {
              statusArray.push("Avaliar", "Corrigir", "Revisar");
            }

            mergedData = mergedData.filter((item: any) => {
              return statusArray.includes(item.state);
            });
          }

          if (filter) {
            mergedData = mergedData.filter(
              (item: any) => item.status === filter
            );
          }

          if (isFavorite) {
            mergedData = mergedData.filter((item) =>
              this.favoriteItems.some((fav) => fav.Id === item.Id)
            );
          }

          this.filteredDataItems = mergedData;
        })
        .catch((error) => {
          console.error("Erro ao aplicar filtros:", error);
        });
    },
    resetCustomFilter() {
      this.filterState = null;
      this.filteredDataItems = this.dataItems;
    },
    addRowToFavorite(payload: TableItemProps) {
      const alreadyFavorited = this.favoriteItems.some(
        (item) => item.Id === payload.Id
      );
      if (!alreadyFavorited) {
        this.favoriteItems.push(payload);
      }
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.Id === payload.Id
      );
      if (index !== -1) {
        this.favoriteItems.splice(index, 1);
      }
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
  },
});
